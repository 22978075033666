<template>
  <div class="flex justify-between px-3">
    <div class="current-selection">
      <h6>{{ dateStart }}</h6>
      <h6>{{ dateEnd }}</h6>
    </div>
    <div class="flex flex-col">
      <Button
        variant="tertiary"
        class=""
        :label="$t('global.datePicker.selectText')"
        @click="$emit('selectDate')"
      />

      <Button
        variant="custom"
        class="bg-white text-error"
        :label="$t('global.datePicker.cancelText')"
        @click="$emit('closePicker')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { Button } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: { Button },
    props: {
      selectText: { type: String, default: 'Select' },
      cancelText: { type: String, default: 'Cancel' },
      internalModelValue: { type: [Date, Array], default: null },
      range: { type: Boolean, default: false },
      previewFormat: {
        type: [String, Function],
        default: () => ''
      },
      monthPicker: { type: Boolean, default: false },
      timePicker: { type: Boolean, default: false }
    },
    emits: ['selectDate', 'closePicker'],
    setup(props) {
      const { t } = useI18n()
      const dateStart = computed(() => {
        if (props.internalModelValue) {
          if (Array.isArray(props.internalModelValue)) {
            const [start]: any[] = props.internalModelValue
            return moment(start).startOf('day').format('LLL')
          }
          return moment(props.internalModelValue).startOf('day').format('LLL')
        }
        return t('global.datePicker.emptyText')
      })

      const dateEnd = computed(() => {
        if (props.internalModelValue) {
          if (Array.isArray(props.internalModelValue)) {
            const [, end]: any[] = props.internalModelValue
            return moment(end).endOf('day').format('LLL')
          }
          return ''
        }
        return ''
      })

      return {
        dateStart,
        dateEnd
      }
    }
  })
</script>

<style lang="scss">
  .custom-action-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .current-selection {
    margin: 10px 0 0 0;
  }

  .select-button {
    display: block;
    background: transparent;
    border: 1px solid var(--dp-success-color);
    color: var(--dp-success-color);
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
  }
</style>
