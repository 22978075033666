
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { Button } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: { Button },
    props: {
      selectText: { type: String, default: 'Select' },
      cancelText: { type: String, default: 'Cancel' },
      internalModelValue: { type: [Date, Array], default: null },
      range: { type: Boolean, default: false },
      previewFormat: {
        type: [String, Function],
        default: () => ''
      },
      monthPicker: { type: Boolean, default: false },
      timePicker: { type: Boolean, default: false }
    },
    emits: ['selectDate', 'closePicker'],
    setup(props) {
      const { t } = useI18n()
      const dateStart = computed(() => {
        if (props.internalModelValue) {
          if (Array.isArray(props.internalModelValue)) {
            const [start]: any[] = props.internalModelValue
            return moment(start).startOf('day').format('LLL')
          }
          return moment(props.internalModelValue).startOf('day').format('LLL')
        }
        return t('global.datePicker.emptyText')
      })

      const dateEnd = computed(() => {
        if (props.internalModelValue) {
          if (Array.isArray(props.internalModelValue)) {
            const [, end]: any[] = props.internalModelValue
            return moment(end).endOf('day').format('LLL')
          }
          return ''
        }
        return ''
      })

      return {
        dateStart,
        dateEnd
      }
    }
  })
